
.affiliates-panel {
  background-color: $white;

  h2.affiliate-title {
    font-size: 38px;
    font-weight: 500;
    color: $black;
    position: relative;
    display: inline-block;
    font-family: $secondary-font;
    text-transform: uppercase;
    margin-bottom: 14px;
    color: $black;

    @include laptop {
      font-size: 40px;
      margin-top: -2px;
    }
  }
}

.testimonials-panel {
  background-color: $brand-primary;
  border-bottom: 10px solid $white;
  padding: 30px 20px;

  @include tablet {
    padding: 50px;
  }

  .testimonial-container {
    background-color: $dark-green;
    border: 2px solid white;
    padding: 34px 0;
    width: 100%;
  }

  .testimonial-titles {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0;

    @include laptop {
      margin-bottom: 66px;
    }

    h2.testimonial-title {
      font-size: 32px;
      font-weight: 500;
      color: $black;
      position: relative;
      display: inline-block;
      font-family: $secondary-font;
      margin-bottom: 10px;
      color: $white;
      line-height: 1;

      @include laptop {
        font-size: 40px;
        margin-bottom: -3px;
        line-height: 1.2;
      }
    }

    p.testimonial-lead {
      font-family: $primary-font;
      margin-bottom: -3px;
      color: $white;
      line-height: 1.2;

      @include laptop {
        line-height: 1.6;
      }
    }
  }

  .tesimonial-space {
    padding-left: 0;

    @include wide {
      padding-left: 15px;
    }
  }

  h3.author {
    font-family: $primary-font;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    font-size: 20px;
    margin-bottom: 14px;
    text-align: center;

    @include desktop {
      text-align: left;
    }
  }

  p.testimonial {
    font-size: 15px;
    line-height: 1.41;
    text-align: center;
    position: relative;
    padding-right: 0;
    color: $white;
    font-weight: 500;

    @include desktop {
      text-align: left;
      padding-right: 10px;
    }
  }

  .quote-icon {
    border-radius: 50%;
    background-color: $brand-primary;
    height: 100px;
    width: 100px;
    border: 2px solid $white;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
      margin-top: 0;
    }

    @include desktop {
      margin-bottom: 30px;
      margin-top: 0;
      height: 90px;
      width: 90px;
    }

    @include hd {
      height: 110px;
      width: 110px;
    }

    img {
      display: block;
      z-index: 999;
      position: relative;
      height: auto;
      width: auto;

      @include desktop {
        height: 35px;
        width: 35px;
      }

      @include hd {
        height: auto;
        width: auto;
      }
    }

    &::before {
      content: '';
      background-color: $white;
      top: 3px;
      bottom: 3px;
      right: 3px;
      left: 3px;
      border-radius: 50%;
      position: absolute;
    }
  }
}

.foothero {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  margin-top: 0;
  z-index: 1;
  min-height: 250px;

  @include tablet {
    min-height: 300px;
  }

  @include desktop {
    height: auto;
    background-position: top;
  }

  .foot-cta-wrap {
    position: relative;
    z-index: 99;
    text-align: center;
    padding: 100px 0;

    @include laptop {
      text-align: left;
    }

    @include desktop {
      padding: 120px 0;
    }

    @include wide {
      padding: 101px 0 110px;
    }

    .btn-container {
      z-index: 99;
      position: relative;
    }

    .foot-cta-box {
      position: relative;
      margin: 0 auto;
      display: none;
      text-align: center;

      @include laptop {
        display: block;
        text-align: left;
      }

      .foot-value-1 {
        font-family: $secondary-font;
        text-transform: uppercase;
        color: $white;
        font-size: 70px;
        font-weight: 500;
        line-height: 0.99;
        margin-bottom: 9px;
        position: relative;
        z-index: 10;
        text-align: center;

        @include laptop {
          text-align: left;
        }

        @include desktop {
          font-size: 76px;
        }

        @include wide {
          font-size: 86px;
        }
      }

      .foot-value-2 {
        font-family: $secondary-font;
        color: white;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.65;
        letter-spacing: 2.1px;
        margin-bottom: 4px;

        @include laptop {
          text-align: left;
        }

        @include desktop {
          font-size: 21px;
          margin-bottom: 28px;
        }
      }
    }
  }
  
  @include desktop {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(22,28,32);
      background: linear-gradient(108deg, rgba(22,28,32,1) 23%, rgba(22,28,32,0) 63%);
    }
  }

  .btn-hero {
    padding: 16px 30px 12px;
  }
}

footer {
  padding-bottom: 33px;
  padding-top: 57px;
  z-index: 100;
  text-align: center;
  background-color: $white;

  nav {
    float: none;
  }

  a.foot-brand {
    margin: 20px auto;
    display: block;
    z-index: 99;
    position: relative;

    @include laptop {
      display: flex;
    }

    @include desktop {
      margin: 5px 0 0 -2px;
    }
  }

  .footer-content {
    display: block;
    position: relative;
    z-index: 999;

    @include laptop {
      display: flex;
    }

    .address {
      margin-left: 19px;
      
    p {
      font-family: $primary-font;
      color: $black;
      display: inline-block;
      padding: 0 3px;
      font-size: 15px;
      font-weight: 400;
      text-align: center;

      @include tablet {
        padding: 0 6px;
      }

      @include laptop {
        text-align: left;
      }
    }

      a.footer-social {
        font-size: 20px;
        color: $black;

        @include links-transition(color, 0.3s, ease-in-out);

        i {
          font-family: 'Font Awesome 5 Brands';
          font-style: normal;
        }
      }

      a.footer-social:hover {
        color: $brand-primary;

        @include links-transition(color, 0.3s, ease-in-out);
      }

      a.footer-phone {
        color: $black;
        display: inline-block;
        font-weight: 400;

        @include links-transition(color, 0.3s, ease-in-out);
      }

      a.footer-phone:hover {
        color: $black;

        @include links-transition(color, 0.3s, ease-in-out);
      }

      i {
        padding: 0 10px 0 0;
      }
    }
  }

  ul.nav {
    position: relative;

    a {
      padding: 0;
    }
  }

  .nav-footer {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    @include tablet {
      @include padding-offset(15px, 'li');
    }

    @include hd {
      margin: 0 auto 0 -56px;
    }

    li.menu-item {
      font-family: $primary-font;
      font-size: 16px;
      display: inline-block;
      margin: 0;
      position: relative;

      @include desktop {
        margin: 0;
        flex: none;
        width: auto;
        max-width: none;
      }

      a {
        color: $black;
        display: inline-block;
        font-weight: 400;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $white;
          height: 1px;
          width: 0;

          @include links-transition();
        }

        br {
          display: none;
        }
      }

      a:hover {
        color: $brand-primary;

        @include links-transition(color, 0.3s, ease-in-out);

        &::after {
          @include links-transition();

          width: 100%;
        }
      }
    }
  }

  p {
    text-align: center;
    font-size: 15px;

    @include laptop {
      font-size: 16px;
    }
  }
}

ul#menu-footer-navigation {
  display: flex;
  flex-direction: column;
  padding-right: 0;
  justify-content: center;

  @include laptop {
    display: block;
    column-count: 2;
  }

  @include desktop {
    display: block;
    column-count: 4;
  }

  li {
    padding: 7px 17.2px;

    @include laptop {
      padding: 0;
      text-align: left;
      width: 100%;
    }

    a {
      font-size: 15px;
      letter-spacing: 0;
    }
  }
}

.copyright {
  padding: 7px 0;
  font-size: 12px;
  font-weight: 400;
  background-color: #f8f8f8;
  color: $black;

  a,
  a:hover {
  color: inherit;
  }

  .copy-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 400;
  
    @include desktop {
      flex-direction: row;
      text-align: left;
    }
  
    .client-copy {
      @include desktop {
        margin-left: auto;
      }
    }
  
    .links-copy,
    .client-copy {
      max-width: 100%;
    }
  }
}