.hero {
  background-position: 84% 50%;
}

.hero,
.interior-hero {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  display: block;
  z-index: 0;
  background-size: cover;
  height: 250px;
  margin-top: 0;

  @include phablet {
    height: 350px;
  }

  @include desktop {
    height: auto;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;

    @include laptop {
      padding-left: 15px;
      padding-right: 15px;
      max-width: 960px;
    }

    @include desktop {
      max-width: 1170px;
    }

    @include wide {
      max-width: 1400px;
    }

    @include hd {
      max-width: 1560px;
    }
  }

  .cta-wrap {
    position: relative;
    padding: 100px 0 170px 40px;
    z-index: 98;
    text-align: center;

    @include phablet {
      padding: 240px 0 170px 40px;
    }

    @include laptop {
      padding: 210px 0;
      text-align: left;
    }

    @include desktop {
      padding: 140px 0;
    }

    @include wide {
      padding: 170px 0;
    }

    @include hd {
      padding: 136px 0 206px;
    }

    .btn-container {
      z-index: 99;
      position: relative;
      margin-left: 1px;
    }

    .cta-box {
      position: relative;
      display: none;
      text-align: center;

      @include laptop {
        display: block;
        text-align: left;
      }

      .value-1 {
        font-family: $secondary-font;
        color: $white;
        text-transform: uppercase;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 1;
        position: relative;
        z-index: 10;
        text-align: center;

        @include laptop {
          text-align: left;
        }

        @include desktop {
          font-size: 68px;
        }

        @include wide {
          font-size: 75px;
        }

        @include hd {
          font-size: 85px;
        }
      }

      .value-2 {
        font-family: $secondary-font;
        color: $white;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2.1px;
        margin-bottom: 29px;
        margin-left: 1px;
        line-height: 1.63;

        @include laptop {
          text-align: left;
        }

        @include desktop {
          font-size: 21px;
        }
      }
    }
  }

  &.interior-hero {
    position: relative;
    min-height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include tablet {
      max-height: auto;
      margin-bottom: 0;
    }

    .cta-wrap {
      position: relative;
      z-index: 99;
      text-align: left;
      padding: 80px 0;

      @include wide {
        padding: 120px 0;
      }
    }

    .cta-box {
      position: relative;
      margin: 0 auto;
      display: none;

      @include laptop {
        display: block;
        padding-bottom: 5px;
      }
    }

    @include desktop {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgb(0,0,0);
        background: linear-gradient(99deg, rgba(0,0,0,0.75) 20%, rgba(0,0,0,0) 100%);
      }
    }
  }
  @include desktop {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(22,28,32);
      background: linear-gradient(108deg, rgba(22,28,32,1) 23%, rgba(22,28,32,0) 63%);
    }
  }
}

.strapline {
  padding: 20px;
  background-color: rgba(#1e1e24, 0.9);
  position: relative;
  width: 100%;
  z-index: 99;
  display: none;

  @include tablet {
    padding: 25px 0;
  }

  @include desktop {
    display: block;
  }

  @include hd {
    padding: 31px 0 41px;
  }

  .strapline-content {
    font-size: 16px;
    color: $white;
    font-weight: 500;
    text-align: center;
    margin: 0;

    @include desktop {
      padding: 8px 0 0 0;
      line-height: 32px;
      font-size: 17px;
      letter-spacing: -0.13px;
    }

    br {
      display: none;

      @include wide {
        display: block;
      }
    }

    h3 {
      color: white;
    }
  }
}

.foot-cta-box-small,
.cta-box-small {
  margin: 0 auto;
  text-align: center;
  padding: 25px 20px 35px;
  position: relative;
  background-color: #1a1a1a;

  @include mobile {
    padding: 25px 50px 35px;
  }

  @include desktop {
    display: none;
  }

  .value-1 {
    font-family: $secondary-font;
    color: $white;
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    position: relative;

    @include phablet {
      font-size: 45px;
    }
  }

  .value-2 {
    font-family: $primary-font;
    color: $white;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0;
    text-transform: uppercase;

    @include phablet {
      font-size: 20px;
    }

    br {
      display: none;
    }
  }
}

.foot-cta-box-small,
.foot-cta-box {
  .value-1 {
    font-family: $secondary-font;
    color: $white;
    font-size: 36px;
    line-height: 1.2;
    position: relative;
    font-weight: 700;

    @include phablet {
      font-size: 45px;
    }
  }
}

.mobile-hero {
  padding-left: 0;
  padding-right: 0;

  @include desktop {
    display: none;
  }

  .strapline-small {
    padding: 20px;
    background-color: $brand-primary;
    width: 100%;
    z-index: 99;

    .strapline-content {
      font-size: 16px;
      color: $white;
      text-align: center;
      margin: 0;
      letter-spacing: -0.4px;
      font-weight: 500;

      br {
        display: none;

        @include desktop {
          display: block;
        }
      }

      h3 {
        color: white;
      }
    }
  }
}
