.why-us-panel {
  background-color: $white;
  
  .why-titles {
    text-align: center;
    margin-top: -1px;

    .why-title {
      font-size: 32px;
      text-transform: uppercase;
      line-height: 0.9;

      @include laptop {
        font-size: 40px;
      }
    }

    .why-lead {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.feature-row {
  margin-top: 0;

  @include laptop {
    margin-top: 30px;
  }

  @include wide {
    margin-top: 50px;
  }
}

.feature-col {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 0;

  @include laptop {
    flex: 0 0 33%;
    max-width: 33%;
    margin-bottom: 40px;
  }

  @include wide {
    flex: 0 0 20%;
    max-width: 20%;
    margin-bottom: 0;
  }

  .quote-icon {
    border-radius: 50%;
    background-color: $light-green;
    height: 100px;
    width: 100px;
    border: 4px solid $white;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  
    @include tablet {
      margin-top: 0;
    }
  
    @include laptop {
      margin-top: 0;
      height: 130px;
      width: 130px;
      margin-bottom: 25px;
    }
  
    img {
      display: block;
      z-index: 999;
      position: relative;
      height: 50px;
      width: 50px;
  
      @include desktop {
        height: 70px;
        width: 70px;
      }
    }
  
    &::before {
      content: '';
      background-color: $white;
      top: 8px;
      bottom: 8px;
      right: 8px;
      left: 8px;
      border-radius: 50%;
      position: absolute;
    }
  }
  
  .features {
    text-align: center;
    padding: 0 10px;
    margin: 0 auto;
    border-right: none;

    @include laptop {
      border-right: 1px solid #adadad;
    }

    @include desktop {
      padding: 0 15px;
    }

    @include wide {
      padding: 0 20px;
    }

    @include hd {
      padding: 0 30px;
    }

    .feature-title {
      color: $black;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 1.1;
      margin-bottom: 10px;

      @include laptop {
        margin-bottom: 24px;
      }

      br {
        display: none;

        @include laptop {
          display: inline-block;
        }
      }
    }

    .feature-text {
      line-height: 1.45;
    }
  }

  &:nth-of-type(5) > .features {
    border-right: none;
  }

  &:nth-of-type(3) > .features {
    border-right: none;
    
    @include wide {
      border-right: 1px solid #adadad;
    }
  }
}

section.services-panel {
  overflow: hidden;
}

.services.odd {
  border-bottom: 10px solid $white;

  .image-half {
    order: 1;
    min-height: 250px;

    @include tablet {
      min-height: 350px;
    }

    @include desktop {
      order: 2;
    }
  }

  .content-half {
    order: 2;

    @include desktop {
      order: 1;
    }

    .service-content {
      margin: 30px 20px;
      padding: 20px 10px;

    @include tablet {
      margin: 50px;
      padding: 40px;
    }

      @include desktop {
        margin: 30px -20px 30px 35px;
        padding: 32px 30px 34px 30px;
      }

      @include wide {
        margin: 50px -20px 50px 53px;
        padding: 53px 60px 54px 70px;
      }

      @include hd {
        margin: 50px -20px 50px 193px;
        padding: 73px 60px 84px 70px;
      }

      .content-wrapper {
        text-align: center;

        @include desktop {
          text-align: left;
        }
      }
    }
  }
}

.services.even {
  .image-half {
    order: 1;
    z-index: 109;
    min-height: 250px;

    @include tablet {
      min-height: 350px;
    }
  }

  .content-half {
    order: 2;
  }
}

.image-half {
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 109;
}

.content-half {
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($brand-primary, 90%);
  }

  .service-content {
    margin: 30px 20px;
    padding: 20px 10px;

    @include tablet {
      margin: 50px;
      padding: 40px;
    }

    @include desktop {
      margin: 30px 33px 30px -20px;
      padding: 33px 30px 34px 30px;
    }

    @include wide {
      margin: 50px 53px 50px -20px;
      padding: 53px 70px 54px 60px;
    }

    @include hd {
      margin: 50px 193px 50px -20px;
      padding: 73px 70px 84px 60px;
    }

    .content-wrapper {
      text-align: center;

      @include desktop {
        text-align: right;
      }
    }
  }
}

.service-content {
  margin: 20px;
  background-color: $dark-green;
  border: 2px solid white;
  position: relative;
  z-index: 99;

  h2,
  p {
    color: white;
  }

  h2 {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 20px;

    @include desktop {
      font-size: 36px;
    }

    @include wide {
      font-size: 40px;
    }
  }

  p {
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 15px;

    @include laptop {
      margin-bottom: 33px;
    }
  }
}

.content-wrapper {
  a.btn-primary {
    border: 1px solid $white;

    &:hover {
     border: 1px solid $white;
    }
  }
}