.btn-primary {
  background-color: $light-green;
  text-transform: uppercase;
  border-radius: 0;
  letter-spacing: -0.5px;
  margin-left: 2px;
  padding: 14px 18px 12px;

  @include links-transition(background-color);

  @include laptop {
    padding: 14px 29.5px 12px;
    letter-spacing: 0.6px;
  }
  
  span {
    display: none;

    @include desktop {
      display: inline-block;
    }
  }

  &:hover {
    background-color: darken($light-green, 10%);

    @include links-transition(background-color);
  }
}

.about-btn,
.btn-hero {
  background-color: $light-green;
  color: $white;
  padding: 15px 30px 11px;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.6px;
  border: none;

  @include links-transition(background-color);

  &:hover {
    background-color: darken($light-green, 10%);

    @include links-transition(background-color);
  }
}

.about-btn {
  padding: 16px 30px 12px;
  margin-top: 20px;
  margin-bottom: 7px;
  font-weight: 600;

  @include desktop {
    margin-top: 39.5px;
  }
}

.menu-cta {
  margin-left: 20px;
  margin-top: 5px;
  font-weight: 600;
}