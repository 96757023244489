.content {
  background-color: #f7f7f6;
}

.card {
  padding: 20px;

  iframe {
    width: 100%;
    height: 450px;
  }
}

a.no-hover,
a.no-hover:hover {
  color: black;
}

.thank-you {
  i.fa-linkedin-in {
    font-size: 26px;
  }
}