#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 100;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

.banner {
  position: relative;
  z-index: 90;
  border-bottom: 1px solid #aeaeae;
  box-shadow: 0px 0px 8px #000;

  a.brand {
    padding: 16px 0 10px;

    img {
      max-width: 210px;

      @include desktop {
        max-width: 260px;
      }

      @include wide {
        max-width: 335px;
      }
    }
  }

  a,
  .phone {
    color: $black;
  }

  a.phone {
    &:after {
      display: none;
    }
  }

  li.menu-cta {
    padding: 15px 0 13px;

    a.top-cta {
      background-color: $light-green;
      font-family: $primary-font;
      text-transform: uppercase;
      color: white;
      padding: 14px 29.5px;
      font-size: 16px;
      letter-spacing: 0.6px;
      border-radius: 0;
      border: none;

      @include links-transition(background-color);

      &:after {
        display: none;
      }

      &:hover {
        background-color: darken($light-green, 10%);
    
        @include links-transition(background-color);
      }
    }
  }
}

.mobile-phone {
  background-color: $brand-primary;
  text-align: right;
  padding: 5px 0;

  a.phone {
    color: $white;
    font-weight: 700;
  }
}

.prim-nav {
  background-color: $brand-primary;
}

.top-primary {
  li {
    padding: 15px 25px 13px;
  
    a {
      padding: 17px 0 0 0;
      font-weight: 500;
      font-size: 19px;
      font-family: $secondary-font;
      text-transform: uppercase;
      color: $black;
      letter-spacing: 0.5px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 7px;
        left: 50%;
        right: 50%;
        background-color: $black;
        height: 1px;
        width: 0;

        @include links-transition();
      }
    }

    a:hover {
      &::after {
        left: 0;
        right: 100%;
        bottom: 0;

        @include links-transition();

        width: 100%;
      }
    }
  }
}

.nav-primary {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: space-between;

  li.menu-item {
    background-color: $brand-primary;
    padding-top: 15px;
    padding-bottom: 13px;
    padding-left: 10px;
    padding-right: 10px;

    @include wide {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  li.menu-item:first-of-type {
    padding-left: 0;
  }

  li.menu-item:last-of-type {
    padding-right: 0;
  }

  a {
    padding: 0;
    font-weight: 500;
    font-size: 17px;
    font-family: $secondary-font;
    text-transform: uppercase;
    color: $white;
    letter-spacing: 0;
    position: relative;

    @include wide {
      letter-spacing: 0.8px;
      font-size: 19px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 50%;
      right: 50%;
      background-color: white;
      height: 1px;
      width: 0;

      @include links-transition();
    }
  }

  a:hover {
    &::after {
      left: 0;
      right: 100%;
      bottom: 0;

      @include links-transition();

      width: 100%;
    }
  }
}
// _____
li.menu-item-has-children {
  position: relative;
}

li.menu-item-has-children.open .sub-menu {
  visibility: visible;
  opacity: 1;
}

.sub-menu {
  visibility: hidden;
  opacity: 0;
  list-style: none;
  z-index: 1;
  padding: 10px 0;

  @include links-transition((visibility, opacity, top));

  a {
    padding: 5px 20px;
    color: #f7f7f7;

    br {
      display: none;
    }
  }

  a:hover {
    color: $white;
    background-color: #494949;
  }
}

.banner .sub-menu {
  box-shadow: 0 3px 5px rgba($black, 0.44);
  position: absolute;
  min-width: 330px;
  width: 110%;
  left: 0;
  text-align: left;
}

.banner .nav-top .sub-menu {
  margin-top: -5px;
  background-color: rgba(black, 1);
}

.banner .nav-primary .sub-menu {
  margin-top: -6px;
  margin-right: -20px;
  background-color: rgba(black, 0.9);
}

.banner,
.sideNav {
  li.menu-item-has-children > a {
    @extend .down-caret;

    &::after {
      margin-left: 7px;
      position: relative;
    }
  }

  li.menu-item-has-children.open > a {
    @extend .up-caret;
  }
}